// Global utilities
import moment from 'moment';
import { start } from 'nprogress';

export const util = {

    // Formats an integer or float for display as currency
    currency: (rate) => {
        return Number(rate).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });
    },
    currencyWithoutFraction: (value) => {
        return Number(Math.round(value)).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        }).replace(/(\.00)$/, '');
    },
    // Converts line breaks to HTML
    // See: https://www.php.net/manual/en/function.nl2br.php
    nl2br: (str) => {
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
    },
    // Converts a string to Title Case
    titleCase: (str) => {
        str = str.toLowerCase().split(' ');
        for (var i = 0; i < str.length; i++) {
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        return str.join(' ');
    },
    // Converts a number/integer of minutes to rounded-down hours and the remainding number of minutes
    toHoursAndMinutes: (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return { hours, minutes };
    },
    // Returns a Date object from the frequently-used YYYY-MM-DD format
    makeDate: (dateString) => {
        return new Date(
            dateString.substr(0, 4),
            Number(dateString.substr(5, 2))-1,
            Number(dateString.substr(8, 2))
        );
    },
    // Converts UTC date from server to local time
    makeLocalTime: (date) => {
        let newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
        let offset = date.getTimezoneOffset() / 60;
        let hours = date.getHours();
        newDate.setHours(hours - offset);
        return newDate;
    },
    getDateFromString(dateString)
    {
        return moment(dateString, 'YYYY-MM-DD HH:mm:ss').toDate();
    },
    // Excessive date formatting function
    // See: https://gist.github.com/kubiqsk/c60207a3075104df7cc1822a95053ecd
    formatDate: (formatStr, date, locale = navigator.language) => {

        const replaceChars = {
            // day
            d: function(){ return ( '0' + this.getDate() ).slice(-2) },
            D: function( locale ){ return new Intl.DateTimeFormat( locale, { weekday: 'short' } ).format( this ) },
            j: function(){ return this.getDate() },
            l: function( locale ){ return new Intl.DateTimeFormat( locale, { weekday: 'long' } ).format( this ) },
            N: function(){
                let day = this.getDay();
                return day === 0 ? 7 : day;
            },
            S: function(){
                let date = this.getDate();
                return date % 10 === 1 && date !== 11 ? 'st' : ( date % 10 === 2 && date !== 12 ? 'nd' : ( date % 10 === 3 && date !== 13 ? 'rd' : 'th' ) );
            },
            J: function(){
                let date = this.getDate();
                return this.getDate() + ( date % 10 === 1 && date !== 11 ? 'st' : ( date % 10 === 2 && date !== 12 ? 'nd' : ( date % 10 === 3 && date !== 13 ? 'rd' : 'th' ) ) );
            },
            w: function(){ return this.getDay() },
            z: function(){ return Math.floor( ( this - new Date( this.getFullYear(), 0, 1 ) ) / 86400000 ) },
            // week
            W: function(){
                let target = new Date( this.valueOf() );
                let dayNr = ( this.getDay() + 6 ) % 7;
                target.setDate( target.getDate() - dayNr + 3 );
                let firstThursday = target.valueOf();
                target.setMonth( 0, 1 );
                if( target.getDay() !== 4 ){
                    target.setMonth( 0, 1 + ( ( 4 - target.getDay() ) + 7 ) % 7 );
                }
                return Math.ceil( ( firstThursday - target ) / 604800000 ) + 1;
            },
            // month
            F: function( locale ){ return new Intl.DateTimeFormat( locale, { month: 'long' } ).format( this ) },
            m: function(){ return ( '0' + ( this.getMonth() + 1 ) ).slice(-2) },
            M: function( locale ){ return new Intl.DateTimeFormat( locale, { month: 'short' } ).format( this ) },
            n: function(){ return this.getMonth() + 1 },
            t: function(){
                let year = this.getFullYear();
                let nextMonth = this.getMonth() + 1;
                if( nextMonth === 12 ){
                    year = year++;
                    nextMonth = 0;
                }
                return new Date( year, nextMonth, 0 ).getDate();
            },
            // year
            L: function(){
                let year = this.getFullYear();
                return year % 400 === 0 || ( year % 100 !== 0 && year % 4 === 0 ) ? 1 : 0;
            },
            o: function(){
                let date = new Date( this.valueOf() );
                date.setDate( date.getDate() - ( ( this.getDay() + 6 ) % 7 ) + 3 );
                return date.getFullYear();
            },
            Y: function(){ return this.getFullYear() },
            y: function(){ return ( '' + this.getFullYear() ).slice(-2) },
            // time
            a: function(){ return this.getHours() < 12 ? 'am' : 'pm' },
            A: function(){ return this.getHours() < 12 ? 'AM' : 'PM' },
            K: function(){ return this.getHours() < 12 ? 'AM' : 'PM' },
            B: function(){
                return ( '00' + Math.floor( ( ( ( this.getUTCHours() + 1 ) % 24 ) + this.getUTCMinutes() / 60 + this.getUTCSeconds() / 3600 ) * 1000 / 24 ) ).slice(-3);
            },
            g: function(){ return this.getHours() % 12 || 12 },
            G: function(){ return this.getHours() },
            h: function(){ return ( '0' + ( this.getHours() % 12 || 12 ) ).slice(-2) },
            H: function(){ return ( '0' + this.getHours() ).slice(-2) },
            i: function(){ return ( '0' + this.getMinutes() ).slice(-2) },
            s: function(){ return ( '0' + this.getSeconds() ).slice(-2) },
            v: function(){ return ( '00' + this.getMilliseconds() ).slice(-3) },
            // Timezone
            e: function(){ return Intl.DateTimeFormat().resolvedOptions().timeZone },
            I: function(){
                let DST = null;
                for( let i = 0; i < 12; ++i ){
                    let d = new Date( this.getFullYear(), i, 1 );
                    let offset = d.getTimezoneOffset();
                    if( DST === null ){
                        DST = offset;
                    }else if( offset < DST ){
                        DST = offset;
                        break;
                    }else if( offset > DST ){
                        break;
                    }
                }
                return ( this.getTimezoneOffset() === DST ) | 0;
            },
            O: function(){
                let timezoneOffset = this.getTimezoneOffset();
                return ( -timezoneOffset < 0 ? '-' : '+' ) + ( '0' + Math.floor( Math.abs( timezoneOffset / 60 ) ) ).slice(-2) + ( '0' + Math.abs( timezoneOffset % 60 ) ).slice(-2);
            },
            P: function(){
                let timezoneOffset = this.getTimezoneOffset();
                return ( -timezoneOffset < 0 ? '-' : '+' ) + ( '0' + Math.floor( Math.abs( timezoneOffset / 60 ) ) ).slice(-2) + ':' + ( '0' + Math.abs( timezoneOffset % 60 ) ).slice(-2);
            },
            T: function( locale ){
                let timeString = this.toLocaleTimeString( locale, { timeZoneName: 'short' } ).split(' ');
                let abbr = timeString[ timeString.length - 1 ];
                return abbr == 'GMT+1' ? 'CET' : ( abbr == 'GMT+2' ? 'CEST' : abbr );
            },
            Z: function(){ return -this.getTimezoneOffset() * 60 },
            // Full Date/Time
            c: function(){ return this.format('Y-m-d\\TH:i:sP') },
            r: function(){ return this.format('D, d M Y H:i:s O') },
            U: function(){ return Math.floor( this.getTime() / 1000 ) }
        }

		return formatStr.replace( /(\\?)(.)/g, function( _, esc, chr ){
			return esc === '' && replaceChars[ chr ] ? replaceChars[ chr ].call( date, locale ) : chr
		});
	},
    // Human-readable shift length string
    friendlyShiftLength: (shiftStart, shiftEnd) => {
        const start = util.getDateFromString(shiftStart).valueOf();
        const end = util.getDateFromString(shiftEnd).valueOf();
        const totals = util.toHoursAndMinutes((end-start) / 60000);
        let string = totals.hours + ' hours';
        if (totals.minutes > 0) {
            string += ', ' + totals.minutes + 'min';
        }
        return string;
    },
    // Convert minutes to hours (I know... I know...)
    toHours: (totalMinutes) => {
        return totalMinutes / 60;
    },
    // Return shift pay in number format, given start and end times, as well as exact rate
    shiftTotal: (shiftStart, shiftEnd, rate) => {
        const start = util.getDateFromString(shiftStart).valueOf();
        const end = util.getDateFromString(shiftEnd).valueOf();
        return Number(util.toHours((end-start) / 60000) * rate).toFixed(2);
    },
    // Return trip value based on mileage rate in global config, in cents
    tripTotal: (trip, config) => {
        return Number(trip.distance * (config.mileageRate * 0.01));
    },
    // Adds a day of padding to a date
    addDayPadding: (date) => {
        var result = new Date(date);
        result.setDate(result.getDate() + 1);
        return result;
    },
    // Returns a friendly date string
    makeFriendlyDate: (dateString, config) => {
        return util.formatDate(config.friendlyDateFormat, moment(dateString, 'YYYY-MM-DD HH:mm:ss').toDate());
    },
    // Returns a compact friendly date string
    makeFriendlyShortDate: (dateString, config) => {
        return util.formatDate(config.friendlyShortDateFormat, moment(dateString, 'YYYY-MM-DD HH:mm:ss').toDate());
    },
    // Returns the total value of the shifts based on their included rates
    sumShifts: (shifts, rates) => {
        let total = 0;
        shifts.forEach(shift => {
            total += Number(util.shiftTotal(shift.start, shift.end, rates[shift.rate_id].amount));
        }, rates);
        return total;
    },
    // Returns the total value of the trips based on the mileage rate in global config
    sumMileage: (trips, config) => {
        let total = 0;
        trips.forEach(trip => {
            total += Number(util.tripTotal(trip, config));
        });
        return total;
    },
    // Returns the total value of the expenses because math is hard
    sumExpenses: (expenses) => {
        let total = 0;
        expenses.forEach(expense => {
            total += Number(expense.amount);
        });
        return total;
    },
    // Returns the remaining value of the shifts after the standard deduction rate in global config
    shiftsDeductions: (shifts, config, rates) => {
        return util.sumShifts(shifts, rates) * (config.deduction / 100);
    },
    // Returns the invoice total based on all data provided and the global config
    invoiceTotal: (invoice, config, rates) => {
        let total = 0;
        if (invoice.shifts && invoice.shifts.length > 0) {
            total += Number((util.sumShifts(invoice.shifts, rates) - util.shiftsDeductions(invoice.shifts, config, rates)));
        }
        if (invoice.trips && invoice.trips.length > 0) {
            total += Number(util.sumMileage(invoice.trips, config));
        }
        if (invoice.expenses && invoice.expenses.length > 0) {
            total += Number(util.sumExpenses(invoice.expenses));
        }
        return total;
    },
    // Returns an object with keys determined by the second parameter
    // Used for remapping all invoices to an object structured by period_id instead of invoice_id
    convertArrayToObject: (array, key) => {
        const initialValue = {};
        return array.reduce((obj, item) => {
            return item !== undefined && key in item ? {
            ...obj,
            [item[key]]: item,
            } : null;
        }, initialValue);
    },
    // Escape any errant HTML in a user-provided string
    escapeString: (str) => {
        var map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;'
        };
        return str.replace(/[&<>"']/g, function(m) { return map[m]; });
    },
    decodeString: (str) => {
        var map = {
            '&amp;': '&',
            '&lt;': '<',
            '&gt;': '>',
            '&quot;': '"',
            '&#039;': "'"
        };
        return str.replace(/&amp;|&lt;|&gt;|&quot;|&#039;/g, function(m) {return map[m];});
    },
    // Comparison function for sorting shifts
    shiftCompare: (a, b) => {
        if ( a.start < b.start ){
          return -1;
        }
        if ( a.start > b.start ){
          return 1;
        }
        return 0;
    },
    getOffset: (el) => {
        const rect = el.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY
        };
    },
    scrollToElement: (id) => {
        // const el = document.getElementById(target);
        // if (el) {
        //     el.scrollIntoView({ block: "center", inline: "nearest", behavior: "instant" });
        // }

        const yOffset = -80;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    },
    addDashesToPhone: (f) => {
        if (!f) return false;
        let r = /(\D+)/g,
            npa = '',
            nxx = '',
            last4 = '';
        f = String(f).replace(r, '');
        npa = f.substr(0, 3);
        nxx = f.substr(3, 3);
        last4 = f.substr(6, 4);
        return npa + '-' + nxx + '-' + last4;
    },
    // toLocalDateString: (zTime) => {
    //     const utcDate = moment(zTime);
    //     const localDate = utcDate.local();
    //     return localDate.format(util.date.format);
    // },
    getQueryParam: (name) => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        return urlParams.get(name)
    },
    stringifyAddressObject: (address) => {
        return address.address + ', ' + address.city + ', ' + address.state + ' ' + address.zip;
    },
    objectifyAddressString: (address) => {
        address = decodeURIComponent(address)
        address.split(', ')
        let splitAddress = address.split(', ')
        return {
            address: splitAddress[0] ? splitAddress[0] : null,
            city: splitAddress[1] ? splitAddress[1] : null,
            state: splitAddress[2] ? splitAddress[2].split(' ')[0] : null,
            zip: splitAddress[2] ? splitAddress[2].split(' ')[1] : null
        };
    },
    isEmpty: (text) => {
        return text == null || text == '' || /^\s*$/.test(text);
    },
    date: {
        date_format: 'YYYY-MM-DD',
        date_display_format: 'MM/DD/YYYY',
        toDateString: (dateObject) => {
            return moment(dateObject).format(util.date.date_format)
        },
        today: () => {
            return moment().format(util.date.date_format)
        },
        lastSunday: (dateString = null) => {
            const date = dateString ? moment(dateString, util.date.date_format) : moment()
            return date.day(0).startOf('day').format(util.date.date_format)
        },
        nextSunday: (dateString = null) => {
            const date = dateString ? moment(dateString, util.date.date_format) : moment()
            return date.startOf('day').endOf('week').add(1,'days').format(util.date.date_format)
        },
        isDayOfWeek: (value, day_of_week) => {
            const date = moment(value, util.date.date_format)
            return date.format('dddd').toLowerCase() == day_of_week
        },
        toString: (dateObject) => {
            return moment(dateObject).format(util.date.date_format)
        },
        toMinute: (time) => {
            const [hours, minutes] = time.split(':').map(Number)
            const totalMinutes = (hours * 60) + minutes
            return totalMinutes
        },
        nextDayOfWeekDate: (start_date, day_of_week) => {
            if (!start_date) {
                return null
            }

            const date = moment(start_date, util.date.date_format)
            if (date.format('dddd').toLowerCase() === day_of_week.toLowerCase()) {
                return date.format(util.date.date_format)
            }

            const nextDate = date.clone().day(day_of_week)
            if (nextDate.isBefore(date, 'day')) {
                nextDate.add(1, 'week')
            }

            return nextDate.format(util.date.date_format)
        },
        previousDayOfWeekDate: (start_date, day_of_week) => {
            if (!start_date) {
                return null
            }

            const date = moment(start_date, util.date.date_format)
            if (date.format('dddd').toLowerCase() === day_of_week.toLowerCase()) {
                return date.format(util.date.date_format)
            }

            const prevDate = date.clone().day(day_of_week)
            if (prevDate.isAfter(date, 'day')) {
                prevDate.subtract(1, 'week')
            }

            return prevDate.format(util.date.date_format)
        },
        nextDate: (start_date, day = 1) => {
            if (!start_date) {
                return null
            }

            const date = moment(start_date, util.date.date_format)
            date.add(day, 'days')

            return date.format(util.date.date_format)
        },
        previousDate: (start_date, day = 1) => {
            if (!start_date) {
                return null
            }

            const date = moment(start_date, util.date.date_format)
            date.subtract(day, 'days')

            return date.format(util.date.date_format)
        },
        generateNext7MomentDays: (start_date) => {
            const date = start_date ? moment(start_date, util.date.date_format) : moment()
            const next7Days = [date]

            for (let i = 1; i < 7; i++) {
                next7Days.push(date.clone().add(i, 'days') )
            }

            return next7Days
        },
        dateDiff: (start_date, end_date) => {
            const startDate = moment(start_date, util.date.date_format)
            const endDate = moment(end_date, util.date.date_format)
            return endDate.diff(startDate, 'days')
        },
        getDayRemainMinute: (value) => {
            const timeMoment = moment(value, 'HH:mm:ss')
            const midnightMoment = moment().startOf('day')

            return 24 * 60 - (timeMoment.diff(midnightMoment, 'minutes'))
        },
        to12CycleTime: (value) => {
            const timeMoment = moment(value, 'HH:mm:ss')
            return timeMoment.format('hh:mm A')
        },
        to24CycleTime: (value) => {
            const timeMoment = moment(value, 'HH:mm:ss')
            return timeMoment.format('HH:mm')
        },
        to12CycleTimeParts: (value) => {
            const timeMoment = moment(value, 'HH:mm:ss')
            return [ timeMoment.format('hh:mm'), timeMoment.format('A') ];
        },
        timeAfterMinute: (value, minute) => {
            const timeMoment = moment(value, 'HH:mm:ss')
            timeMoment.add(minute, 'minutes')

            return timeMoment.format('HH:mm:ss')
        },
        generateTimeFrame: (anchorDateString, number_of_week) => {
            const anchorDate = moment(anchorDateString, util.date.date_format)
            const lowerBoundDate = anchorDate.clone().subtract(number_of_week, 'weeks')
            const upperBoundDate = anchorDate.clone().add(number_of_week, 'weeks')

            return [
                lowerBoundDate.format(util.date.date_format),
                upperBoundDate.format(util.date.date_format)
            ]
        },
        getWeekText: (dateString) => {
            const inputDate = moment(dateString, util.date.date_format)

            const today = moment().startOf('day');
            const lastSunday = today.clone().startOf('week')
            const nextSaturday = today.clone().endOf('week')

            const pastSunday = lastSunday.clone().subtract(1, 'week').startOf('week')
            const pastSaturday = lastSunday.clone().subtract(1, 'day')

            const nextSunday = nextSaturday.clone().add(1, 'day').startOf('week')
            const nextNextSaturday = nextSaturday.clone().add(1, 'week')

            if (inputDate.isSameOrAfter(pastSunday) && inputDate.isSameOrBefore(pastSaturday)) {
                return 'Last Week'
            }

            if (inputDate.isSameOrAfter(lastSunday) && inputDate.isSameOrBefore(nextSaturday)) {
                return 'This Week'
            }

            if (inputDate.isSameOrAfter(nextSunday) && inputDate.isSameOrBefore(nextNextSaturday)) {
                return 'Next Week'
            }

            const weekStart = inputDate.clone().startOf('week').format(util.date.date_display_format)
            const weekEnd = inputDate.clone().endOf('week').format(util.date.date_display_format)
            return `${weekStart} - ${weekEnd}`
        },
        getTimeRangeText: (day_of_week, start_time, duration = 0) => {
            const formattedStart = `${day_of_week} ${start_time}`;

            const startMoment = moment(formattedStart, 'dddd HH:mm:ss');
            const endMoment = startMoment.clone().add(duration, 'minutes')

            const formattedStartTime = startMoment.format('h:mma')
            const formattedEndTime = endMoment.format('h:mma')

            const shortDayOfWeek = moment().day(day_of_week).format('ddd')

            const nextDay = endMoment.date() !== startMoment.date()

            let result = `${shortDayOfWeek} ${formattedStartTime}`
            if (nextDay) {
                result += `-${endMoment.format('ddd')} ${formattedEndTime}`
            } else {
                result += `-${formattedEndTime}`;
            }

            return result
        },
        durationToHour: (duration) => {
            const hour = Math.floor(duration / 60)
            const minute = duration % 60

            return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
        },
        durationToHours: (minutes) => {
            const hours = minutes / 60
            return hours % 1 === 0 ? hours : parseFloat(hours.toFixed(2))
        }
    },
    client: {
    },
    schedule: {
        isDateOverlap: (sunday, day_of_week, start_date, end_date, duration) => {
            const sundayDate = moment(sunday, util.date.date_format)
            const nextSundayDate = sundayDate.clone().add(7, 'days')
            const startDate = moment(start_date, util.date.date_format)
            const endDate = end_date ? moment(end_date, util.date.date_format).add(duration, 'minutes') : null
            const currentDayOfWeekDate = moment(util.date.nextDayOfWeekDate(sunday, day_of_week), util.date.date_format)

            if (startDate.isAfter(currentDayOfWeekDate) || (endDate != null && endDate.isBefore(currentDayOfWeekDate))) {
                return false
            }

            if (startDate.isSameOrBefore(sundayDate)) {
                if (endDate == null || endDate.isSameOrAfter(sundayDate)) {
                    return true
                }
            }

            if (startDate.isAfter(sundayDate) && startDate.isBefore(nextSundayDate)) {
                return true
            }

            return false
        },
        isTimeOverlap: (start_time_a, duration_a, start_time_b, duration_b) => {
            const start_moment_a = moment(start_time_a, 'HH:mm:ss')
            const end_moment_a = start_moment_a.clone().add(duration_a, 'minutes')
            const start_moment_b = moment(start_time_b, 'HH:mm:ss')
            const end_moment_b = start_moment_b.clone().add(duration_b, 'minutes')

            if (start_moment_a.isBetween(start_moment_b, end_moment_b, undefined, '[)')
                || start_moment_b.isBetween(start_moment_a, end_moment_a, undefined, '[)')) {
                return true;
            }

            return false;
        },
        is2DaysTime: (start_time, duration) => {
            const timeMoment = moment(start_time, 'HH:mm:ss')
            const midnightMoment = moment().startOf('day')

            return (timeMoment.diff(midnightMoment, 'minutes') + duration) > 24 * 60
        },
        span: (duration) => {
            return Math.floor(duration / 15) * 3
        },
        sizeOf: (block) => {
            let span = util.schedule.span(block.duration)

            if (span > 138) { return 'xl'; }
            if (span > 108) { return 'lg'; }
            if (span > 84) { return 'md'; }
            if (span > 36) { return 'sm'; }
            if (span > 14) { return 'xs'; }
            if (span > 0) { return '2xs'; }
        },
        rateOfWithoutFraction: (block) => {
            if (block.charge_rate_amount) {
                return util.currencyWithoutFraction(block.charge_rate_amount);
            }
            if (block.min_charge_rate_amount && !block.max_charge_rate_amount) {
                return util.currencyWithoutFraction(block.min_charge_rate_amount);
            }
            if (!block.min_charge_rate_amount && block.max_charge_rate_amount) {
                return util.currencyWithoutFraction(block.max_charge_rate_amount);
            }
            if (block.min_charge_rate_amount && block.max_charge_rate_amount) {
                return util.currencyWithoutFraction(block.min_charge_rate_amount) + '-' + util.currencyWithoutFraction(block.max_charge_rate_amount);
            }
            return undefined;
        },
        rateOf: (block) => {
            if (block.charge_rate_amount) {
                return util.currency(block.charge_rate_amount);
            }
            if (block.min_charge_rate_amount && !block.max_charge_rate_amount) {
                return util.currency(block.min_charge_rate_amount);
            }
            if (!block.min_charge_rate_amount && block.max_charge_rate_amount) {
                return util.currency(block.max_charge_rate_amount);
            }
            if (block.min_charge_rate_amount && block.max_charge_rate_amount) {
                return util.currency(block.min_charge_rate_amount) + '-' + util.currency(block.max_charge_rate_amount);
            }
            return undefined;
        },
        start: (time) => {
            const timeMoment = moment(time, 'HH:mm:ss')
            const midnightMoment = moment().startOf('day')
            return 2 +  Math.floor(timeMoment.diff(midnightMoment, 'minutes') / 15) * 3
        },
        getWeekSpecificDate: (sunday_string, day_of_week) => {
            const sundayDate = moment(sunday_string, util.date.date_format);

            const targetDayIndex = moment().day(day_of_week).day();
            const targetDate = sundayDate.day(targetDayIndex);

            return targetDate.format(util.date.date_format);
        },
        hasFutureReOccurrence: (sunday_string, start_date_string, end_date_string) => {
            if (end_date_string == null) {
                return true
            }

            const sundayDate = moment(sunday_string, util.date.date_format)
            const nextSundayDate = sundayDate.clone().add(7, 'days')
            const startDate = moment(start_date_string, util.date.date_format)
            const endDate = moment(end_date_string, util.date.date_format)

            if (
                startDate.isBefore(sundayDate)
                && (endDate.diff(startDate, 'days') / 7) >= 2
                && endDate.isSameOrAfter(nextSundayDate)) {
                return true;
            }

            if (
                startDate.isSameOrAfter(sundayDate)
                && (endDate.diff(startDate, 'days') / 7) >= 1
                && endDate.isSameOrAfter(nextSundayDate)) {
                return true;
            }

            return false
        },
        getChargeRateAmount: (caregiver_id, day_of_week, charge_duration_rate_type, charge_rate_list) => {
            let type = ['sunday', 'saturday'].includes(day_of_week) ? 'weekend' : (!day_of_week ? '' : 'weekday')
            type += `_${charge_duration_rate_type}`
            const index = charge_rate_list.findIndex(item => (item.type === type && item.caregiver_id == caregiver_id))

            if (index > -1) {
                return charge_rate_list[index].amount
            }

            return null
        },
        getShiftDurationWithinRage: (day_of_week, start_date, end_date, duration_per_occurrence) => {
            let nextDayOfWeek = util.date.nextDayOfWeekDate(start_date, day_of_week)
            let duration = 0
            while (nextDayOfWeek < end_date) {
                duration += duration_per_occurrence
                const nextDate = util.date.nextDate(nextDayOfWeek)
                nextDayOfWeek = util.date.nextDayOfWeekDate(nextDate, day_of_week)
            }

            return duration
        },
        momentAndDurationToRangeTime: (start_time, duration) => {
            let end_time = start_time.clone().add(duration, 'minutes')
            return [
                {
                    hours: start_time.format('H'),
                    minutes: start_time.format('m')
                },
                {
                    hours: end_time.format('H'),
                    minutes: end_time.format('m')
                }
            ];
        }
    },
    getSmallerItem: (a, b) => {
        return (a < b) ? a : b;
    },
    getLargerItem: (a, b) => {
        return (a > b) ? a : b;
    }
}

export function debounce(func, delay) {
    let timer = null;
    return function(...args) {
        clearTimeout(timer);
        timer = setTimeout(() => {
        func.apply(this, args);
        }, delay);
    };
}

export function isAttributeEqual(obj1, obj2, key) {
    if (((typeof obj1[key] === 'object' && obj1[key] !== null) || Array.isArray(obj1[key])) ||
        ((typeof obj2[key] === 'object' && obj2[key] !== null) || Array.isArray(obj2[key]))) {
        return JSON.stringify(obj1[key]) == JSON.stringify(obj2[key])
    }

    return obj1[key] == obj2[key]
}

export function getModifiedProperties(newValue, oldValue, fields) {
    const object1 = JSON.parse(newValue);
    const object2 = JSON.parse(oldValue);

    function findDifferences(newObject, oldObject, fields) {
        const diff = {};

        for (const i in fields) {
            const key = (typeof fields[i] !== 'object') ? fields[i] : fields[i]['key']
            const diffFunc = (typeof fields[i] !== 'object') ? null : fields[i]['diff']
            const sortFunc = (typeof fields[i] !== 'object') ? null : fields[i]['sort']

            if (typeof newObject[key] === 'object' && newObject[key] !== null && !Array.isArray(newObject[key])) {
                if (diffFunc) {
                    const diffResult = diffFunc(newObject[key], oldObject[key])
                    if (diffResult) {
                        diff[key] = diffResult
                    }
                }
            // Be careful as we only use none strict compare
            } else if (!Array.isArray(newObject[key]) && newObject[key] != oldObject[key]) {
                diff[key] = newObject[key]
            } else if (Array.isArray(newObject[key])) {
                if (diffFunc) {
                    const diffResult = diffFunc(newObject[key], oldObject[key])
                    if (diffResult) {
                        diff[key] = diffResult
                    }
                } else {
                    if (arraysHaveSameItems(newObject[key], oldObject[key], sortFunc) === false) {
                        diff[key] = newObject[key];
                    }
                }
            }

        }

        return diff;
    }

    function arraysHaveSameItems(arr1, arr2, sortFunc) {
        if (arr1.length !== arr2.length) return false;

        const sortedArr1 = sortFunc ? arr1.slice().sort(sortFunc) : arr1.slice().sort();
        const sortedArr2 = sortFunc ? arr2.slice().sort(sortFunc) : arr2.slice().sort();

        for (let i = 0; i < sortedArr1.length; i++) {
            if (typeof(sortedArr1[i]) === 'object' && typeof(sortedArr2[i]) === 'object') {
                if (Object.keys(findDifferences(sortedArr1[i], sortedArr2[i], Object.keys(sortedArr1[i]))).length > 0) {
                    return false;
                }
            } else if (sortedArr1[i] !== sortedArr2[i]) {
                return false;
            }
        }

        return true;
    }

    return findDifferences(object1, object2, fields);
}

export function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj))
}
