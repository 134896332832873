<script setup>

    import { deepCopy, util } from '@/Helpers'
    import moment from 'moment'
    import { ref, computed, watch } from 'vue'

    import LatteClientScheduleViewBlock from '@/Components/Client/LatteClientScheduleViewBlock.vue'

    import { useClientInvoiceShiftStore, useClientShiftStore } from '@/Stores'
    import LatteSchedule from '@/Components/LatteSchedule.vue'

    const clientShiftStore = useClientShiftStore()
    const clientInvoiceShiftStore = useClientInvoiceShiftStore()

    const emit = defineEmits(['shifts:selected'])

    const props = defineProps({
        client: {
            type: [Object, null],
            required: true
        },
        start_date: {
            type: String,
            required: true
        },
        planned_shifts: {
            type: Array,
            required: false,
            default: []
        },
        selected_shift_ids: {
            type: Array,
            required: true
        },
        formLoading: {
            type: Boolean,
            default: true
        }
    })

    const selectedIds = ref([...props.selected_shift_ids])

    let clientShifts
    let clientShiftMap
    let blocks
    let selectedCondition

    function toggleSelect(block) {
        if (!isCurrentOrFutureWeek.value) { return false;}
        if (!canBeSelected(block)) { return false; }

        if (props.planned_shifts.length > 0 && selectedIds.value.length == 0) { return false; }

        if (selectedIds.value.includes(block.id)) {
            selectedIds.value = selectedIds.value.filter(item => item != block.id)
        } else {
            selectedIds.value.push(block.id)
        }

        emit('shifts:selected', selectedIds.value)
    }


    const isCurrentOrFutureWeek = computed(() => {
        const lastSunday = moment(util.date.lastSunday(), util.date.date_format)
        const startDate = moment(props.start_date, util.date.date_format)

        return startDate.isSameOrAfter(lastSunday);
    })

    function shiftToBlock(shift) {

        const block = {
            ...shift,
            'start_date': util.schedule.getWeekSpecificDate(props.start_date, shift.day_of_week)
        }
        // delete block['day_of_week']

        if (util.schedule.is2DaysTime(shift.start_time, shift.duration)) {
            const dayRemainMinute = util.date.getDayRemainMinute(shift.start_time)

            if (!util.date.isDayOfWeek(shift.start_date, 'saturday')) {
                const data = [{
                    ...block,
                    duration: dayRemainMinute,
                    continuation_duration: shift.duration - dayRemainMinute,
                    continues: true
                }]
                data.push({
                    ...block,
                    start_time: '00:00:00',
                    shift_start_time: shift.start_time,
                    start_date: util.date.nextDate(block.start_date),
                    duration: shift.duration - dayRemainMinute,
                    continuation: true
                })
                return data
            }

            const data = []

            if (shift.end_date == null || props.start_date < shift.end_date) {
                data.push({
                    ...block,
                    duration: dayRemainMinute,
                    continuation_duration: shift.duration - dayRemainMinute,
                    continues: true
                })
            }

            const endDateWithTime = shift.end_date == null ? null : moment(shift.end_date, util.date.date_format).add(shift.duration, 'minutes').format(util.date.date_format)
            if (props.start_date > shift.start_date && (endDateWithTime == null || props.start_date <= endDateWithTime)) {
                data.push({
                    ...block,
                    start_time: '00:00:00',
                    shift_start_time: shift.start_time,
                    day_of_week: 'sunday',
                    start_date: props.start_date,
                    duration: shift.duration - dayRemainMinute,
                    continuation: true
                })
            }
            return data;
        }

        return [block];
    }

    watch(() => props.selected_shift_ids, (newValue) => {
        selectedIds.value = [...newValue]
    })

    function popperTheme(block) {
        if (!block.assigned_caregiver.id && isCurrentOrFutureWeek.value) {
            return 'lifeworx-schedule-red'
        } else if (block.assigned_caregiver.id && isCurrentOrFutureWeek.value) {
            return 'lifeworx-schedule-blue'
        } else {
            return 'lifeworx-schedule-gray'
        }
    }

    function canBeSelected(block) {
        if (!block.id) {
            return false;
        }

        if (props.planned_shifts.length > 0 && selectedIds.value.length == 0) {
            return false;
        }

        if (!selectedCondition.value) {
            return true;
        }

        if (selectedCondition.value.stay_type != block.stay_type) {
            return false;
        }

        if (selectedCondition.value.duration_type == 'ongoing' && block.end_date) {
            return false;
        }

        if (selectedCondition.value.duration_type == 'temp' && !block.end_date) {
            return false;
        }

        return true;
    }

    function orderBlocks(data) {

        let sortedData = []
        const daysOfTheWeek = [ 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday' ]

        daysOfTheWeek.forEach((day, index) => {
            const indexBefore = index == 0 ? 6 : index - 1
            let dayBlocks = data.filter((block) => (block.day_of_week == day && !block.continuation) || (index >= 1 && block.day_of_week == daysOfTheWeek[indexBefore] && block.continuation))
            dayBlocks.sort(function sortByTime(a, b) {
                return moment(a.start_time, 'HH:mm:ss').diff(moment(b.start_time, 'HH:mm:ss'));
            })

            sortedData.push(...dayBlocks)

        })
        return sortedData;
    }

    watch([() => props.client, () => props.start_date], ([client, start_date]) => {
        if (start_date < util.date.lastSunday()) {
            clientShifts = clientInvoiceShiftStore.computedClientShift(client.id)
            clientShiftMap = clientInvoiceShiftStore.computedClientShiftMap(client.id)
        } else {
            clientShifts = clientShiftStore.computedClientShift(client.id)
            clientShiftMap = clientShiftStore.computedClientShiftMap(client.id)
        }

        blocks = computed(() => {
            //Have to reference start date to trigger recompute
            if (props.start_date && props.client) {}

            const clientShiftsClone = deepCopy(clientShifts.value)
            props.planned_shifts.forEach(shift => {
                if (shift.id) {
                    const index = clientShiftsClone.findIndex(item => item.id == shift.id)
                    clientShiftsClone[index] = shift
                }
            })

            let data = []
            clientShiftsClone.forEach(shift => {
                if (util.schedule.isDateOverlap(props.start_date, shift.day_of_week, shift.start_date, shift.end_date, shift.duration)) {
                    data = data.concat(shiftToBlock(shift))
                }
            })

            props.planned_shifts.forEach(shift => {
                if (!shift.id && util.schedule.isDateOverlap(props.start_date, shift.day_of_week, shift.start_date, shift.end_date, shift.duration)) {
                    data = data.concat(shiftToBlock(shift))
                }
            })

            if (data.length > 1) {
                data.forEach(blockA => {
                    data.forEach(blockB => {
                        if (blockA.id !== blockB.id && blockA.start_date == blockB.start_date && util.schedule.isTimeOverlap(blockA.start_time, blockA.duration, blockB.start_time, blockB.duration)) {
                            blockA.overlaps = true
                            blockB.overlaps = true
                        }
                    })
                })
            }

            return orderBlocks(data);
        })

        selectedCondition = computed(() => {
            if (selectedIds.value.length == 0) {
                return null;
            }

            const firstSelectedShift = clientShifts.value[clientShiftMap.value[selectedIds.value[0]]]

            return {
                stay_type: firstSelectedShift.stay_type,
                duration_type: firstSelectedShift.end_date ? 'temp' : 'ongoing'
            };
        })
    }, {immediate: true, deep: true})



</script>

<template>
    <LatteSchedule :start_date="props.start_date">
        <ol
            class="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-6 overflow-hidden"
            style="grid-template-rows: 0.4rem repeat(288, minmax(0, 1fr)) auto"
        >
            <li
                v-for="block in blocks"
                class="relative flex group justify-center"
                :class="`col-start-${util.date.dateDiff(props.start_date, block.start_date) + 1}`"
                :style="`grid-row: ${util.schedule.start(block.start_time)} / span ${util.schedule.span(block.duration)}`"
            >
                <VDropdown
                    :triggers="['hover']"
                    :theme="popperTheme(block)"
                    no-auto-focus
                    placement="top"
                    :disabled="['lg', 'xl', '2xl'].includes(util.schedule.sizeOf(block)) || (!isCurrentOrFutureWeek && !['2xs', 'xs', 'sm'].includes(util.schedule.sizeOf(block)))"
                >
                    <LatteClientScheduleViewBlock
                        :isCurrentOrFutureWeek="isCurrentOrFutureWeek"
                        :block="block"
                        :size="util.schedule.sizeOf(block)"
                        :canBeSelected="canBeSelected(block)"
                        :selectedIds="selectedIds"
                        class="group absolute flex inset-0.5 flex-col text-xs whitespace-nowrap"
                        :class="[{
                            'invisible': formLoading,
                            'rounded-t-lg': block.continues,
                            'rounded-b-lg': block.continuation,
                            'rounded-lg': !block.continues && !block.continuation,
                            'p-0.5 border-2 ml-0.5 mr-2': ['2xs', 'xs'].includes(util.schedule.sizeOf(block)),
                            'p-1 border-2 ml-0.5 mr-2': ['sm', 'md', 'lg', 'xl', '2xl'].includes(util.schedule.sizeOf(block)),
                            'z-40 hover:z-50 group-even:mr-8 group-odd:ml-8 group-odd:mr-0.5 backdrop-brightness-200 backdrop-saturate-0 backdrop-blur-sm': block.overlaps,
                            'cursor-pointer opacity-90 border-opacity-80 hover:opacity-100 hover:bg-opacity-[0.3] hover:backdrop-blur-sm': canBeSelected(block) && isCurrentOrFutureWeek,
                            'cursor-default opacity-70 border-opacity-50 bg-opacity-[0.1]': !canBeSelected(block) && block.id,
                            'backdrop-blur-sm bg-opacity-[0.2] ': !canBeSelected(block) && block.id && block.overlaps,
                            'border-dashed': !block.id && isCurrentOrFutureWeek,
                            'bg-opacity-[0.2] bg-lifeworx-red-500 border-lifeworx-red-500 text-lifeworx-red-500 ': !block.assigned_caregiver.id && isCurrentOrFutureWeek,
                            'bg-opacity-[0.2] border-lifeworx-blue-900 bg-lifeworx-blue-900 text-lifeworx-blue-900 p-2': !isCurrentOrFutureWeek,
                            'bg-opacity-[0.3] border-opacity-100': selectedIds.includes(block.id),
                            'bg-opacity-[0.2] bg-lifeworx-blue-600 border-lifeworx-blue-600 text-lifeworx-blue-600': block.assigned_caregiver.id && isCurrentOrFutureWeek,
                        }]"
                        @click="() => toggleSelect(block)"
                    />
                    <template #popper>
                        <LatteClientScheduleViewBlock
                            class="px-3 pt-0 pb-8"
                            :isCurrentOrFutureWeek="isCurrentOrFutureWeek"
                            :block="block"
                            :size="'2xl'"
                            :canBeSelected="canBeSelected(block)"
                            :selectedIds="selectedIds"
                        />
                    </template>
                </VDropdown>
            </li>
        </ol>
    </LatteSchedule>
</template>
