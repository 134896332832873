<script setup>

    import { computed, ref, watch } from 'vue'
    import VueDatePicker from '@vuepic/vue-datepicker'
    import '@vuepic/vue-datepicker/dist/main.css'
    import { ClockIcon } from '@heroicons/vue/24/outline'
    import { util } from '@/Helpers'
    import moment from 'moment'

    const props = defineProps({
        startTime: {
            type: [String, null],
            default: '09:00'
        },
        duration: {
            type: [Number, null],
            default: 720
        },
        stayType: {
            type: [String, null],
            default: 'live_out'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        teleport: {
            type: [String, Boolean],
            default: false
        }
    })

    const liveOutTimePicker = ref(null)
    const multiUpdate = ref(false)

    const emit = defineEmits(['update:startTime', 'update:duration'])

    const isMultiple = computed(() => {
        return (props.startTime == null || props.duration == null);
    });

    const internalValue = computed({
        get: () => {
            if (props.stayType == 'live_out') {
                if (isMultiple.value) {
                    multiUpdate.value = false
                } else {
                    return util.schedule.momentAndDurationToRangeTime(moment(props.startTime, 'HH:mm'), props.duration)
                }
            } else {
                return util.schedule.momentAndDurationToRangeTime(moment(props.startTime, 'HH:mm'), 24 * 60)[0]
            }
        },
        set: (value) => {
            if (value == null) { return false; }
            if (props.stayType == 'live_out') {
                const oldStartHour = parseFloat(props.startTime.split(':')[0])
                const oldStartMinute = parseFloat(props.startTime.split(':')[1])
                let duration = 16 * 60

                if (value[0].hours == value[1].hours && value[0].minutes == value[1].minutes && value[0].seconds == value[1].seconds) {
                    duration = 16 * 60
                } else if (moment(value[0].hours + ':' + value[0].minutes, 'k:m') > moment(value[1].hours + ':' + value[1].minutes, 'k:m')){
                    duration = moment(value[1].hours + ':' + value[1].minutes, 'k:m').clone().add(24, 'hours').diff(moment(value[0].hours + ':' + value[0].minutes, 'k:m'), 'minutes')
                } else {
                    duration = moment(value[1].hours + ':' + value[1].minutes, 'k:m').diff(moment(value[0].hours + ':' + value[0].minutes, 'k:m'), 'minutes')
                }

                if (value[0].hours != oldStartHour || value[0].minutes != oldStartMinute) {
                    emit('update:startTime', moment(value[0].hours + ':' + value[0].minutes, 'k:m').format('HH:mm'))
                    if (duration > 16 * 60) {
                        duration = 16 * 60
                    }
                } else if (duration > 16 * 60) {
                    let newHour = value[1].hours - 16
                    newHour = newHour > 0 ? newHour : newHour + 24
                    emit('update:startTime', moment(newHour + ':' + value[1].minutes, 'k:m').format('HH:mm'))
                    duration = 16 * 60
                }

                emit('update:duration', duration)
            } else {
                emit('update:startTime', moment(value.hours + ':' + value.minutes, 'k:m').format('HH:mm'))
                emit('update:duration', 24 * 60)
            }
        }
    })

    watch(props, (newValue) => {
        if (liveOutTimePicker.value) {
            if (newValue.duration == 16 * 60) {
                liveOutTimePicker.value.closeMenu()
                setTimeout(() => liveOutTimePicker.value.openMenu(), 1 )
            }
        }
    }, {immediate:true, deep:true})

    function multipleClick() {
        if (isMultiple.value && !multiUpdate.value) {
            multiUpdate.value = true
            liveOutTimePicker.value.closeMenu()
            emit('update:startTime', '09:00')
            emit('update:duration', 720)
            setTimeout(() => liveOutTimePicker.value.openMenu(), 1 )
        }
    }

</script>

<template>
    <div>

        <VueDatePicker
            v-if="props.stayType == 'live_out'"
            v-model="internalValue"
            time-picker
            :clearable="false"
            :range="{ disableTimeRangeValidation: true }"
            :disabled="props.disabled"
            minutes-increment="15"
            minutes-grid-increment="15"
            :is24="false"
            :autoApply="true"
            :teleport="props.teleport"
            placeholder="Multiple"
            format="h:mmaaa"
            ref="liveOutTimePicker"
            :ui="{ input: 'font-semibold rounded-lg text-center border-stone-400 focus:border focus:border-lifeworx-blue-600 focus:ring-0 focus:outline focus:outline-3' }"
            :transitions="props.duration == 16 * 60 || isMultiple ? false : true"
            @open="multipleClick"
        >
            <template #input-icon>
                <ClockIcon class="inline-flex mx-2 h-5 w-5 -mt-0.5 text-stone-500 input-slot-image" />
            </template>
        </VueDatePicker>

        <VueDatePicker
            v-else
            v-model="internalValue"
            time-picker
            :clearable="false"
            :disabled="props.disabled"
            minutes-increment="15"
            minutes-grid-increment="15"
            :is24="false"
            :autoApply="true"
            :teleport="props.teleport"
            format="h:mmaaa"
            :ui="{ input: 'font-semibold rounded-lg text-center border-stone-400 focus:border focus:border-lifeworx-blue-600 focus:ring-0 focus:outline focus:outline-3' }"
        >
            <template #input-icon>
                <ClockIcon class="inline-flex mx-2 h-5 w-5 -mt-0.5 text-stone-500 input-slot-image" />
            </template>
        </VueDatePicker>

    </div>
</template>
