<script setup>

    import { util } from '@/Helpers'
    import { ref } from 'vue'

    import { IconArrowSync20Filled, IconHome20Regular, IconVehicleCarProfile20Regular, IconArrowImport20Regular, IconArrowImport16Regular, IconArrowSync16Filled, IconHome16Regular, IconVehicleCarProfile16Regular } from '@iconify-prerendered/vue-fluent'

    import LatteStaffLabel from '@/Components/LatteStaffLabel.vue'

    import { useStaffStore } from '@/Stores'

    const staffStore = useStaffStore()

    const props = defineProps({
        isCurrentOrFutureWeek: {
            type: Boolean
        },
        block: {
            type: Object
        },
        size: {
            type: String
        },
        canBeSelected: {
            type: Boolean
        },
        selectedIds: {
            type: Array
        }
    })

    const loadingImages = ref([])

</script>

<template>
    <div
        :class="[
            'overflow-hidden',
            {
                'opacity-75': !canBeSelected,
                'text-lifeworx-red-500': !block.assigned_caregiver?.id && isCurrentOrFutureWeek,
                'text-lifeworx-blue-600': block.assigned_caregiver?.id,
                'text-lifeworx-blue-900': !isCurrentOrFutureWeek,
                'w-36': size == '2xl'
            }]"
        >
        <!-- Checkbox -->
        <input
            v-if="isCurrentOrFutureWeek"
            type="checkbox"
            class="rounded focus:ring-transparent border border-stone-400"
            :class="[{
                'absolute right-2 top-0.5 h-2.5 w-2.5': ['2xs'].includes(size),
                'absolute right-2 top-1/3 h-3 w-3': ['xs'].includes(size),
                'absolute right-2 top-2': ['sm', 'md', 'lg', 'xl', '2xl'].includes(size),
                'bg-stone-100 border border-stone-300 border-opacity-50 cursor-not-allowed ': !canBeSelected && block.id,
                'bg-lifeworx-red-500 border-lifeworx-red-500 text-lifeworx-red-500': !block.assigned_caregiver?.id && selectedIds.includes(block.id) || !block.id && !block.assigned_caregiver.id,
                'bg-lifeworx-blue-600 border-lifeworx-blue-600 text-lifeworx-blue-600': block.assigned_caregiver?.id && selectedIds.includes(block.id) || !block.id,
            }]"
            :checked="selectedIds.includes(block.id) || !block.id"
            :disabled="!canBeSelected"
        />

        <div :class="[{
            'flex flex-col items-stretch space-y-0.5': size == 'xs',
            'absolute left-0.5 top-0 flex flex-col items-stretch space-y-0.5': size == 'sm' && isCurrentOrFutureWeek,
            'space-y-0.5': size == 'md' && isCurrentOrFutureWeek,
            'space-y-1.5': size == 'lg' && isCurrentOrFutureWeek,
            'space-y-1.5': size == 'xl' && isCurrentOrFutureWeek,
            'space-y-2': size == '2xl' && isCurrentOrFutureWeek,
            'relative h-full': !isCurrentOrFutureWeek
        }]">

            <!-- Status -->
            <div v-if="['xl', '2xl'].includes(size)">
                <p class="text-stone-500 italic font-semibold text-sm"><span v-if="!block.id">Unsaved</span><span v-else>&nbsp;</span></p>
            </div>

            <!-- Time -->
            <div v-if="['xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(size)">
                <span
                    :class="[{
                        'text-xs font-bold my-0 leading-none': size == 'xs',
                        'text-sm font-bold my-0 leading-snug': size == 'sm',
                        'text-sm font-bold my-0 leading-snug': size == 'md',
                        'text-base font-bold my-0 leading-tight': size == 'lg',
                        'text-base font-bold my-1 leading-tight': size == 'xl',
                        'text-xl font-bold my-1 leading-tight': size == '2xl',
                    }, 'whitespace-nowrap font-bold']"
                >
                    {{ util.date.to12CycleTimeParts(block.continuation ? block.shift_start_time : block.start_time)[0] }}<span class="text-xs">{{ util.date.to12CycleTimeParts(block.continuation ? block.shift_start_time : block.start_time)[1] }}</span><br />

                    &ndash;{{ util.date.to12CycleTimeParts(util.date.timeAfterMinute(block.start_time, block.continues ? block.duration+block.continuation_duration : block.duration))[0] }}<span class="text-xs">{{ util.date.to12CycleTimeParts(util.date.timeAfterMinute(block.start_time, block.continues ? block.duration+block.continuation_duration : block.duration))[1] }}</span>
                </span>
            </div>

            <!-- Types -->
            <div
                v-if="['lg', 'xl', '2xl'].includes(size) && isCurrentOrFutureWeek"
                :class="[{
                    'space-y-1.5': size == 'lg',
                    'space-y-1.5 ': size == 'xl',
                    'space-y-1.5 text-sm': size == '2xl',
                }]"
            >
                <div>
                    <IconArrowImport20Regular v-if="block.end_date" class="inline-flex h-5 w-5 mr-1" />
                    <IconArrowSync20Filled v-else class="inline-flex h-5 w-5 mr-1" />
                    <span class="font-bold tracking-tight">{{ block.end_date ? 'Temp' : 'Ongoing' }}</span>
                </div>
                <div>
                    <IconHome20Regular v-if="block.stay_type == 'live_in'" class="inline-flex h-5 w-5 mr-1" />
                    <IconVehicleCarProfile20Regular v-else class="inline-flex h-5 w-5 mr-1" />
                    <span class="font-bold tracking-tight">{{ block.stay_type == 'live_in' ? 'Live-In' : 'Live-Out'}}</span>
                </div>
            </div>
            <div
                v-if="['md'].includes(size) && isCurrentOrFutureWeek"
                :class="[{
                    'font-bold space-y-0.5': size == 'md',
                }]"
            >
                <div>
                    <IconArrowImport16Regular v-if="block.end_date" class="inline-flex h-4 w-4 mr-1" />
                    <IconArrowSync16Filled v-else class="inline-flex h-4 w-4 mr-1" />
                    <span>{{ block.end_date ? 'Temp' : 'Ongoing' }}</span>
                </div>
                <div>
                    <IconHome16Regular v-if="block.stay_type == 'live_in'" class="inline-flex h-4 w-4 mr-1" />
                    <IconVehicleCarProfile16Regular v-else class="inline-flex h-4 w-4 mr-1" />
                    <span>{{ block.stay_type == 'live_in' ? 'Live-In' : 'Live-Out'}}</span>
                </div>
            </div>
            <div v-if="['sm'].includes(size) && isCurrentOrFutureWeek">
                <IconArrowImport16Regular v-if="block.end_date" class="inline-flex h-4 w-4" />
                <IconArrowSync16Filled v-else class="inline-flex h-4 w-4" />
                <IconHome16Regular v-if="block.stay_type == 'live_in'" class="inline-flex h-4 w-4" />
                <IconVehicleCarProfile16Regular v-else class="inline-flex h-4 w-4" />
            </div>

            <!-- Rate -->
            <div v-if="['md', 'lg', 'xl', '2xl'].includes(size) && util.schedule.rateOf(block)">
                <span class="text-sm font-bold">{{ util.schedule.rateOf(block) }}<span class="text-2xs font-semibold">/{{ block.charge_duration_rate_type == 'hourly' ? 'HR' : 'day' }}</span></span>
            </div>

            <!-- Assignment -->
            <div
                v-if="['lg', 'xl', '2xl'].includes(size) || (!isCurrentOrFutureWeek && !['2xs', 'xs', 'sm'].includes(size))"
                :class="[
                    'font-semibold flex flex-col',
                    {
                        'mt-4': ['md', 'lg'].includes(size),
                        'mt-6': ['xl', '2xl'].includes(size),
                    }
                ]"
            >
                <div class="flex flex-row -mt-1 mb-2 place-items-center">

                    <div
                        class="flex-shrink-0 rounded-full overflow-clip h-6 w-6 border z-30 -ml-0.5 mr-1 text-center"
                        :class="{
                            'border-lifeworx-red-500 bg-lifeworx-red-500 bg-opacity-10': block.assigned_caregiver?.hard_no_at || !block.assigned_caregiver?.id,
                            'border-zinc-300 bg-white': !block.assigned_caregiver?.hard_no_at && block.assigned_caregiver.id ,
                            'text-sm': ['md', 'lg', 'xl', '2xl'].includes(size)
                        }"
                    >

                        <img
                            v-if="block.assigned_caregiver?.id"
                            :src="block.assigned_caregiver.profile_image_url"
                            :alt="block.assigned_caregiver.first_name + ' ' + block.assigned_caregiver.last_name"
                            class="flex object-cover rounded-full"
                            :class="{
                                'invisible': loadingImages[block.assigned_caregiver.profile_image_url]
                            }"
                            @load="loadingImages[block.assigned_caregiver.profile_image_url] = false"
                        />

                        <span v-else>!</span>

                    </div>

                    <div
                        :class="[
                            'flex tracking-tight font-semibold text-xs text-wrap',
                            {
                                'italic': !block.assigned_caregiver?.id,
                                'text-lifeworx-blue-800': block.assigned_caregiver?.id,
                            }
                        ]"
                    >
                        {{ block.assigned_caregiver?.id ? `${block.assigned_caregiver.first_name} ${block.assigned_caregiver.last_name}` : 'Unassigned'}}
                    </div>

                </div>


                <LatteStaffLabel
                    v-if="block.assigned_caregiver?.assigned_recruiter?.id"
                    class="my-0.5"
                    :actionable="false"
                    :staff="staffStore.getStaffById(block.assigned_caregiver.assigned_recruiter.id)"
                    size="xs"
                    :wrapping="true"
                />


                <div
                    v-if="block.assigned_caregiver?.profile_image_url && loadingImages[block.assigned_caregiver.profile_image_url]"
                    class="flex w-full h-full justify-center items-center"
                >
                    <svg
                        class="h-6 w-6 animate-spin text-zinc-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    >
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>

            </div>

        </div>
    </div>
</template>
